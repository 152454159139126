/** @jsxRuntime classic */
/** @jsx jsx */
import { graphql, PageProps } from "gatsby"
import { jsx, Box, Container, AspectRatio } from "theme-ui"
import ReactPlayer from "react-player"

import type { SingleAmbassadorQuery } from "../../graphql-types"

import PageHero from "components/PageHero"
import Layout from "components/Layout"
import MdContent from "components/common/MdContent"

interface SingleAmbassadorProps extends PageProps {
  data: SingleAmbassadorQuery
}

const AmbassadorTemplate = ({ data, ...props }: SingleAmbassadorProps) => {
  return (
    <Layout seo={data.strapiAmbassador?.seo} location={props.location}>
      <PageHero
        title={data.strapiAmbassador?.name}
        image={
          data.strapiAmbassador?.intro_image?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        imageAlt={data.strapiAmbassador?.intro_image?.alternativeText}
        subtitle={data.strapiAmbassador?.specialty}
        content={data.strapiAmbassador?.intro}
        backlinkUrl="/ambassadors"
        imagePosition="top"
      />

      <Box as="section" sx={{ my: [12, null, 28] }}>
        <Container variant="narrow" sx={{ px: [8, null, 4] }}>
          <MdContent source={data.strapiAmbassador?.content} />
        </Container>

        {data.strapiAmbassador?.video?.URL && (
          <Container
            sx={{
              my: 12,
              position: "relative",
            }}
            variant="narrow"
          >
            <AspectRatio ratio={16 / 9}>
              <ReactPlayer
                className="react-player"
                url={data.strapiAmbassador?.video?.URL}
                width="100%"
                height="100%"
                sx={{
                  left: 0,
                  position: "absolute",
                  top: 0,
                }}
              />
            </AspectRatio>
          </Container>
        )}
      </Box>
    </Layout>
  )
}

export default AmbassadorTemplate

export const pageQuery = graphql`
  query SingleAmbassador($slug: String!) {
    strapiAmbassador(slug: { eq: $slug }) {
      id
      specialty
      name
      intro
      content
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      intro_image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      video {
        URL
      }
    }
  }
`
